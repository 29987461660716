import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, of } from 'rxjs';

import { customException } from '../../../utils/capture-exception.util';
import { DemoModeGroup, Demonstration } from './demo-mode.interface';
import { mockDemonstration, mockGroups } from './demo-mode.mock';

/**
 * Сервис, предоставляющий функциональные возможности и данные, специфичные для демо-режима.
 *
 * Этот сервис включает методы для получения групп в демо-режиме и демонстраций.
 */
@Injectable({ providedIn: 'root' })
export class DemoModeService {
  /**
   * Получает наблюдаемый список групп в демо-режиме.
   *
   * @return {Observable<DemoModeGroup[]>} Наблюдаемый объект, испускающий массив объектов DemoModeGroup. В случае ошибки вернется наблюдаемый объект, который испускает пустой массив.
   */
  getGroups(): Observable<DemoModeGroup[]> {
    return of(mockGroups).pipe(
      catchError((err) => {
        customException({ msg: 'getGroups', err });

        return of([]);
      }),
    );
  }

  /**
   * Получает демонстрацию в виде Observable.
   *
   * @return {Observable<Demonstration>} Observable объект, emitting объект Demonstration. В случае ошибки возвращается пустой Observable.
   */
  getDemonstration(): Observable<Demonstration> {
    return of(mockDemonstration).pipe(
      catchError((err) => {
        customException({ msg: 'getDemonstration', err });
        return EMPTY;
      }),
    );
  }
}
