import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { Injectable, computed, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withState } from '@ngrx/signals';
import { addEntity, removeEntity, setEntities, setEntity, updateEntity, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Observable, filter, pipe, switchMap, tap } from 'rxjs';

import { BuildingCard } from '../../3dmodels/models3d.interface';
import { Status } from '../../../../enums/status.enum';
import { DemoModeGroup, Demonstration, Slide } from '../demo-mode.interface';
import { rootGroupId } from '../demo-mode.mock';
import { DemoModeService } from '../demo-mode.service';
import { DemoModeState, demoModeDemonstrationConfig, demoModeGroupConfig, demoModeSlideConfig } from './demo-mode.store.type';

/**
 * Сервис хранилища слоев.
 * @class
 */
@Injectable({ providedIn: 'root' })
export class DemoModeStoreService extends signalStore(
  { protectedState: false },
  withDevtools('demo-mode'),
  withState<DemoModeState>({
    groupStatus: Status.UNINITIALIZED,
    demonstrationStatus: Status.UNINITIALIZED,
    error: '',
    isSelectGroupModalOpen: false,
  }),
  withEntities(demoModeGroupConfig),
  withEntities(demoModeDemonstrationConfig),
  withEntities(demoModeSlideConfig),
) {
  /**
   * Функция, которая вычисляет, является ли статус равен UNINITIALIZED.
   * @returns {boolean} Возвращает true, если статус равен UNINITIALIZED, в противном случае возвращает false.
   */
  isGroupUninitialized = computed(() => this.groupStatus() === Status.UNINITIALIZED);
  /**
   * Функция, которая вычисляет, загружается ли данные.
   * @returns {boolean} Возвращает true, если статус равен LOADING, иначе false.
   */
  isGroupLoading = computed(() => this.groupStatus() === Status.LOADING);
  /**
   * Функция, которая возвращает результат вычисления, является ли статус равен "Загружено".
   * @returns {boolean} Результат вычисления, является ли статус равен "Загружено".
   */
  isGroupLoaded = computed(() => this.groupStatus() === Status.LOADED);

  /**
   * Функция, которая вычисляет, является ли статус равен UNINITIALIZED.
   * @returns {boolean} Возвращает true, если статус равен UNINITIALIZED, в противном случае возвращает false.
   */
  isDemonstrationUninitialized = computed(() => this.demonstrationStatus() === Status.UNINITIALIZED);
  /**
   * Функция, которая вычисляет, загружается ли данные.
   * @returns {boolean} Возвращает true, если статус равен LOADING, иначе false.
   */
  isDemonstrationLoading = computed(() => this.demonstrationStatus() === Status.LOADING);
  /**
   * Функция, которая возвращает результат вычисления, является ли статус равен "Загружено".
   * @returns {boolean} Результат вычисления, является ли статус равен "Загружено".
   */
  isDemonstrationLoaded = computed(() => this.demonstrationStatus() === Status.LOADED);

  /**
   * Реактивный сервис, который предоставляет доступ к информации о маршруте и состоянии маршрутизатора.
   *
   * Эта переменная является экземпляром класса `ActivatedRoute` и позволяет:
   * - Просматривать конфигурацию, специфичную для маршрута.
   * - Доступаться к параметрам маршрута и параметрам запроса.
   * - Обнаруживать изменения информации о маршруте.
   * - Выполнять программную навигацию.
   *
   * Полезно в сценариях, где необходимо реагировать на изменения маршрута или извлекать информацию из текущего маршрута.
   * Обычно используется в Angular компонентах или сервисах, где важен контекст маршрута.
   *
   * Этот экземпляр внедряется с использованием системы внедрения зависимостей Angular.
   */
  #activatedRoute = inject(ActivatedRoute);

  /**
   * Сервис, используемый для управления состоянием демо-режима приложения.
   *
   * Данный сервис предоставляет функциональность для включения, отключения и проверки статуса
   * демо-режима в приложении. Демо-режим обычно используется для демонстрации возможностей или запуска
   * приложения в образцовом состоянии для обучения или презентации.
   *
   * DemoModeService может предоставлять различные методы и свойства для управления этими аспектами,
   * и этот сервис внедряется везде, где требуется функциональность демо-режима.
   *
   * @type {DemoModeService}
   */
  #demoModeService = inject(DemoModeService);

  /**
   * Переменная `router` является экземпляром класса Router, предоставляемого при помощи механизма внедрения зависимостей.
   *
   * В основном используется для навигации между различными маршрутами в приложении, управления логикой навигации,
   * выполнения перенаправлений и управления событиями изменения маршрутов. Используя внедрение зависимостей,
   * экземпляр `Router` может быть легко внедрён и использован везде, где необходимы функции маршрутизации.
   *
   * Тип: Router
   *
   * Контекст использования:
   * Может использоваться в компонентах, сервисах или в любом месте, где требуется навигация по маршрутам.
   *
   * Типичные случаи использования:
   * - Программная навигация к различным маршрутам
   * - Перенаправление пользователей на основе определенных условий
   * - Прослушивание изменений маршрутов и выполнение соответствующей логики
   */
  #router = inject(Router);

  /**
   * Извлекает и обрабатывает группы на основе заданных условий.
   *
   * Эта функция использует RxJS pipeline для фильтрации, обновления состояния
   * и переключения на новый observable, который загружает группы.
   *
   * Pipeline состоит из следующих этапов:
   * - Фильтр, чтобы продолжать только когда объект не инициализирован.
   * - Tap для обновления статуса состояния на LOADING.
   * - SwitchMap для преобразования потока и загрузки групп.
   */
  readonly getGroups = rxMethod<void>(
    pipe(
      filter(() => this.isGroupUninitialized()),
      tap(() => patchState(this, { groupStatus: Status.LOADING })),
      switchMap(() => this.loadGroups()),
    ),
  );

  /**
   * Метод для получения демонстрации.
   * Этот метод сначала проверяет, не инициализирована ли демонстрация, затем помечает состояние как загружающееся,
   * и завершает операцию загрузкой демонстрации.
   */
  readonly getDemonstration = rxMethod<void>(
    pipe(
      filter(() => this.isDemonstrationUninitialized()),
      tap(() => patchState(this, { demonstrationStatus: Status.LOADING })),
      switchMap(() => this.loadDemonstration()),
    ),
  );

  readonly addSlide = rxMethod<Slide>(pipe(tap((slide) => patchState(this, addEntity(slide, demoModeSlideConfig)))));

  readonly deleteSlide = rxMethod<string>(pipe(tap((id) => patchState(this, removeEntity(id, demoModeSlideConfig)))));

  readonly updateSlide = rxMethod<Slide>(
    pipe(
      tap((slide) =>
        patchState(
          this,
          updateEntity(
            {
              id: slide.id,
              changes: { name: slide.name },
            },
            demoModeSlideConfig,
          ),
        ),
      ),
    ),
  );

  /**
   * Устанавливает состояние для открытия или закрытия модального окна выбора группы.
   *
   * @param {boolean} value - Новое состояние модального окна выбора группы.
   *                          Если true, модальное окно будет открыто; если false, оно будет закрыто.
   *
   * @return {void}
   */
  setIsSelectGroupModalOpen(value: boolean): void {
    patchState(this, { isSelectGroupModalOpen: value });
  }

  /**
   * Определяет, находится ли указанное здание в новом демонстрационном режиме.
   * @param {string} buildingId - Идентификатор проверяемого здания.
   * @return {boolean} - Возвращает true, если здание находится в новом демонстрационном режиме, иначе - false.
   */
  isNewDemoMode(buildingId: BuildingCard['buildingId']): boolean {
    return this.groupsEntities().some((group) => group.buildingIds.includes(buildingId));
  }

  /**
   * Открывает демонстрационный режим для конкретного здания. Если демонстрационный режим новый для данного здания,
   * добавляет карточку здания в группу и переходит к разделу демонстрационного режима.
   *
   * @param {number} buildingId - Уникальный идентификатор карточки здания.
   * @return {void} Нет возвращаемого значения.
   */
  openDemoMode(buildingId: BuildingCard['buildingId']): void {
    if (this.isNewDemoMode(buildingId)) {
      this.addObjectCard2Group(buildingId, rootGroupId);
    }

    this.#router.navigate([{ outlets: { primary: null, 'demo-mode': ['demo-mode', buildingId] } }], {
      queryParams: this.#activatedRoute.snapshot.queryParams,
    });
  }

  /**
   * Загружает группы и обновляет состояние, устанавливая сущности и статус.
   *
   * @return {Observable<DemoModeGroup[]>} - Наблюдаемый объект, испускающий список групп в демо-режиме.
   */
  private loadGroups(): Observable<DemoModeGroup[]> {
    return this.#demoModeService.getGroups().pipe(
      tapResponse({
        next: (groups) => patchState(this, setEntities(groups, demoModeGroupConfig)),
        error: console.error,
        finalize: () => patchState(this, { groupStatus: Status.LOADED }),
      }),
    );
  }

  /**
   * Загружает демонстрацию и возвращает её как Observable.
   *
   * @return {Observable<Demonstration>} Observable, испускающий объект Demonstration. В случае ошибки возвращается пустой Observable.
   */
  private loadDemonstration(): Observable<Demonstration> {
    return this.#demoModeService.getDemonstration().pipe(
      tap((demonstration) => {
        patchState(this, setEntities(demonstration.items, demoModeSlideConfig));
      }),
      tapResponse({
        next: (demonstration) => patchState(this, setEntity(demonstration, demoModeDemonstrationConfig)),
        error: console.error,
        finalize: () => patchState(this, { demonstrationStatus: Status.LOADED }),
      }),
    );
  }

  /**
   * Добавляет карточку здания в указанную группу, обновляя список идентификаторов зданий группы.
   *
   * @param {BuildingCard['buildingId']} buildingId - Идентификатор карточки здания, добавляемой в группу.
   * @param {string} groupId - Идентификатор группы, в которую будет добавлена карточка здания.
   * @return {void} Нет возвращаемого значения.
   */
  private addObjectCard2Group(buildingId: BuildingCard['buildingId'], groupId: string): void {
    patchState(
      this,
      updateEntity(
        { id: rootGroupId, changes: { buildingIds: [...this.groupsEntityMap()[groupId].buildingIds, buildingId] } },
        demoModeGroupConfig,
      ),
    );
  }
}
