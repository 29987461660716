import { type } from '@ngrx/signals';
import { entityConfig } from '@ngrx/signals/entities';

import { Status } from '../../../../enums/status.enum';
import { DemoModeGroup, Demonstration, Slide } from '../demo-mode.interface';

export const demoModeGroupConfig = entityConfig({
  entity: type<DemoModeGroup>(),
  collection: 'groups',
  selectId: (group) => group.id,
});

export const demoModeDemonstrationConfig = entityConfig({
  entity: type<Demonstration>(),
  collection: 'demonstration',
  selectId: (demonstration) => demonstration.id,
});

export const demoModeSlideConfig = entityConfig({
  entity: type<Slide>(),
  collection: 'slides',
  selectId: (slide) => slide.id,
});

export interface DemoModeState {
  groupStatus: Status;
  demonstrationStatus: Status;
  error: string;
  isSelectGroupModalOpen: boolean;
}
